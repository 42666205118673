import React from 'react'
import Newnavbar from './Newnavbar'
import Newfooter from './Newfooter'
import p1 from '../Pages/Newimages/p1.png'
import p2 from '../Pages/Newimages/p2.png'
import p3 from '../Pages/Newimages/p3.png'
import SvgIcons from './SvgIcons'


const Pricing = () => {
  return (
    <div>
            <Newnavbar />
            <div className="pricing_bg">
                <div className="pricing_bg_des container mx-auto ">
                <h1>Smart Payment Solutions for Your Business</h1>
                <div className="pricing_box">
                    <h3>Standard Plan</h3>
                    <p className='accept'>Accept all modes of payments at all customer touchpoints</p>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="gold_bg">
                                <img src={p1} alt="" />
                                <h6 className='not'>IMPS / UPI Payouts</h6>
                                <p>Instant. Secure. 24/7 IMPS Transfers</p>

                                <hr className='pricing_hr'/>

                                <ul>
                                    <li><SvgIcons.Stars/> <p>₹1 to ₹1000 - <span>Flat Rs.10/-</span></p></li>
                                    <li><SvgIcons.Stars/> <p>₹1001 Onwards - <span>1%</span></p> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                        <div className="gold_bg white_bg">
                                <img src={p2} alt="" />
                                <h6>NEFT</h6>
                                <p>Hassle-Free NEFT Transfers</p>

                                <hr className='pricing_hr'/>

                                <ul>
                                    <li><SvgIcons.Stars/> <p>₹1 to ₹1000 - <span>Flat Rs.5/-</span></p></li>
                                    <li><SvgIcons.Stars/> <p>₹1001 Onwards - <span>0.3%</span></p> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                        <div className="gold_bg">
                                <img src={p3} alt="" />
                                <h6>UPI Collections</h6>
                                <p> Secure UPI Collections</p>

                                <hr className='pricing_hr'/>

                                <ul>
                                    <li><SvgIcons.Stars/> <p>₹1 to ₹1000 - <span>Flat Rs.20/-</span></p></li>
                                    <li><SvgIcons.Stars/> <p>₹1001 Onwards - <span>2%</span></p> </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="gst_all">
                        <h4>*GST 18% Applicable Extra*</h4>
                        <a href='/Contactus'>Try it Now</a>
                    </div>
                </div>

                </div>

           <div className="second_all container mx-auto">
           <div className="pricing_box_second">
                    <h3> Special & Customize Plans</h3>
                    <p className='accept'>Smart & secure payment solution to boost your business growth.</p>

                    <div className="gst_all">
                        <h4>*GST 18% Applicable Extra*</h4>
                        <a href='/Contactus'>Contact Now</a>
                    </div>
           </div>
       
           </div>

            </div>
            <Newfooter />
      
    </div>
  )
}

export default Pricing
