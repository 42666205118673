import React from "react";

const Stars = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <g clip-path="url(#clip0_215_295)">
    <path d="M3.58114 16.1691C3.40584 16.1691 3.23177 16.1144 3.0832 16.007C2.80646 15.8064 2.67719 15.4617 2.75251 15.129L3.78183 10.5949L0.291017 7.5335C0.0344181 7.30949 -0.0636068 6.95475 0.0417426 6.63015C0.147092 6.30617 0.43372 6.07679 0.773085 6.04542L5.39186 5.6261L7.21795 1.35267C7.3526 1.0387 7.65925 0.835938 7.99996 0.835938C8.34066 0.835938 8.64731 1.0387 8.78196 1.35194L10.6081 5.6261L15.2261 6.04542C15.5662 6.07606 15.8528 6.30617 15.9582 6.63015C16.0635 6.95414 15.9661 7.30949 15.7095 7.5335L12.2187 10.5942L13.248 15.1283C13.3235 15.4617 13.1941 15.8064 12.9174 16.0064C12.6414 16.2063 12.2734 16.2217 11.9827 16.047L7.99996 13.667L4.01719 16.0484C3.88254 16.1283 3.73251 16.1691 3.58114 16.1691ZM7.99996 12.6417C8.15133 12.6417 8.30123 12.6823 8.436 12.7623L12.1948 15.0104L11.2233 10.7308C11.154 10.4263 11.2574 10.1083 11.4927 9.90221L14.7888 7.01151L10.428 6.6155C10.114 6.58682 9.844 6.38942 9.72131 6.10011L7.99996 2.06729L6.27652 6.10072C6.15518 6.38808 5.88515 6.58547 5.57191 6.61416L1.21047 7.01017L4.50646 9.90087C4.74255 10.1075 4.84582 10.4249 4.77587 10.7302L3.80515 15.0096L7.56391 12.7623C7.69856 12.6823 7.84858 12.6417 7.99996 12.6417ZM5.35658 5.70874C5.35658 5.70874 5.35658 5.70948 5.35584 5.71009L5.35658 5.70874ZM10.642 5.70679L10.6427 5.70813C10.6427 5.7074 10.6427 5.7074 10.642 5.70679Z" fill="#D2981C"/>
  </g>
  <defs>
    <clipPath id="clip0_215_295">
      <rect width="16" height="16" fill="white" transform="translate(0 0.492188)"/>
    </clipPath>
  </defs>
</svg>
);



const SvgIcons = {
    Stars,
};

export default SvgIcons;
