import React, { useState } from "react";
// import Logo from "../Images/PaypiLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import '../Pages/Newhomepage.css'

const Newnavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className=" navmain_container">
      <div className="nav_items container ">
        {/* Burger Icon for Mobile */}
        <div className="burger_icon" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} size="lg" color="#FCE14A" />
        </div>

        {/* Logo */}
        <div className="logo_container">
          <a href="/">
            {/* <img src={Logo} alt="Paybi Logo" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="97" height="32" viewBox="0 0 97 32" fill="none">
              <path d="M70.0352 6.17532V30.3157H79.4487C81.2584 30.3157 82.8001 29.966 84.0739 29.2666C85.3477 28.5751 86.3185 27.6125 86.9865 26.3787C87.6622 25.1528 88.0001 23.7384 88.0001 22.1353C88.0001 20.5322 87.6583 19.1178 86.9748 17.8919C86.2913 16.666 85.3011 15.7112 84.004 15.0276C82.7147 14.3439 81.1535 14.0021 79.3205 14.0021H73.3206V18.0923H78.505C79.4759 18.0923 80.2759 18.2612 80.905 18.5991C81.5419 18.9449 82.0156 19.4203 82.3263 20.0254C82.6448 20.6383 82.804 21.3416 82.804 22.1353C82.804 22.9368 82.6448 23.6362 82.3263 24.2334C82.0156 24.8385 81.5419 25.3061 80.905 25.6361C80.2681 25.974 79.4603 26.143 78.4817 26.143H75.0798V6.17532H70.0352Z" fill="url(#paint0_linear_1655_507)" />
              <path d="M70.0352 25.8246V1.6842L79.4487 1.6842C81.2584 1.6842 82.8001 2.03389 84.0739 2.73327C85.3477 3.42479 86.3185 4.38742 86.9865 5.62116C87.6622 6.84703 88.0001 8.26151 88.0001 9.86458C88.0001 11.4676 87.6583 12.8821 86.9748 14.108C86.2913 15.3339 85.3011 16.2886 84.004 16.9723C82.7147 17.656 81.1535 17.9978 79.3205 17.9978H73.3206V13.9076H78.505C79.4759 13.9076 80.2759 13.7387 80.905 13.4008C81.5419 13.055 82.0156 12.5796 82.3263 11.9745C82.6448 11.3616 82.804 10.6583 82.804 9.86458C82.804 9.06304 82.6448 8.36366 82.3263 7.76644C82.0156 7.16136 81.5419 6.6938 80.905 6.36375C80.2681 6.02585 79.4603 5.8569 78.4817 5.8569L75.0798 5.8569V25.8246H70.0352Z" fill="url(#paint1_linear_1655_507)" />
              <rect x="90.8066" y="8.9823" width="5.61404" height="21.3333" rx="0.842105" fill="url(#paint2_linear_1655_507)" />
              <rect x="90.8066" y="1.68408" width="5.61404" height="5.61404" rx="0.842105" fill="url(#paint3_linear_1655_507)" />
              <rect width="52.7719" height="32" rx="3.36842" fill="url(#paint4_linear_1655_507)" />
              <path d="M26.3857 17.163L32.9622 23.7394C32.9622 23.7394 42.1484 14.5532 48.3498 8.35181" stroke="#373328" stroke-width="6.73684" />
              <path d="M8.4209 26.3861L8.4209 5.61414H16.3636C17.8905 5.61414 19.1913 5.91503 20.2661 6.51682C21.3408 7.11185 22.16 7.94016 22.7236 9.00174C23.2937 10.0566 23.5788 11.2737 23.5788 12.6531C23.5788 14.0324 23.2904 15.2496 22.7137 16.3044C22.1371 17.3592 21.3015 18.1807 20.2071 18.769C19.1192 19.3573 17.802 19.6514 16.2554 19.6514H11.193V16.132H15.5673C16.3865 16.132 17.0615 15.9866 17.5923 15.6958C18.1297 15.3983 18.5294 14.9892 18.7916 14.4686C19.0603 13.9412 19.1946 13.336 19.1946 12.6531C19.1946 11.9634 19.0603 11.3616 18.7916 10.8477C18.5294 10.327 18.1297 9.92472 17.5923 9.64073C17.0549 9.34997 16.3734 9.2046 15.5477 9.2046H12.6773V26.3861H8.4209Z" fill="#373328" />
              <defs>
                <linearGradient id="paint0_linear_1655_507" x1="90.3468" y1="30.3157" x2="70.5141" y2="31.057" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFC039" />
                  <stop offset="0.8291" stop-color="#FFD95E" />
                </linearGradient>
                <linearGradient id="paint1_linear_1655_507" x1="90.3468" y1="1.68421" x2="70.5141" y2="0.94285" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFC039" />
                  <stop offset="0.8291" stop-color="#FFD95E" />
                </linearGradient>
                <linearGradient id="paint2_linear_1655_507" x1="97.154" y1="8.9823" x2="90.9487" y2="8.90028" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFC039" />
                  <stop offset="0.8291" stop-color="#FFD95E" />
                </linearGradient>
                <linearGradient id="paint3_linear_1655_507" x1="97.154" y1="1.68408" x2="90.9633" y2="1.37312" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFC039" />
                  <stop offset="0.8291" stop-color="#FFD95E" />
                </linearGradient>
                <linearGradient id="paint4_linear_1655_507" x1="59.6656" y1="2.8551e-06" x2="1.72294" y2="-4.79968" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFC039" />
                  <stop offset="0.8291" stop-color="#FFD95E" />
                </linearGradient>
              </defs>
            </svg>
          </a>

          <div className="nav_list_items">
            <ul>
              <li style={{ color: "#FFD95E" }}>
                <a href="/" style={{ color: "#FFD95E" }}>Home</a>
              </li>
              <li>
                <a href="/">Features</a>
              </li>
              <li>
                <a href="/pricing">Pricing</a>
              </li>
              <li>
                <a href="/aboutus">About Us</a>
              </li>
              <li>
                <a href="/Contactus">Contact Us</a>
              </li>
            </ul>
          </div>

        </div>

        {/* Navigation Items */}


        {/* Login Button */}



        <div className="Login_btn">
          <a
            href="https://app.paybi.in/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register
          </a>
        </div>
      </div>

      {/* Mobile Side Menu */}
      <div className={`mobile_menu ${isMenuOpen ? "open" : ""}`}>
        <div className="close_btn" onClick={toggleMenu}>
          {/* <FontAwesomeIcon icon={faTimes} size="lg"  /> */}
          <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: 'white' }} />

        </div>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/">Features</a>
          </li>
          <li>
            <a href="/pricing">Pricing</a>
          </li>
          <li>
            <a href="/aboutus">About Us</a>
          </li>
          <li>
            <a href="/Contactus">Contact Us</a>
          </li>
          <li><a href="https://app.paybi.in/register" target="_blank" rel="noopener noreferrer">Register</a></li>

        </ul>
      </div>
    </div>
  );
};

export default Newnavbar;
